(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/wager-payment-flow/payment-failure/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/wager-payment-flow/payment-failure/assets/javascripts/utils.js');
"use strict";


const {
  ClientErrorCodes,
  ClientErrorIcon
} = svs.components.payment.common.error.constants;
const {
  urlMapping,
  jupiter
} = svs.core;
const {
  Confirm,
  branding,
  message,
  button
} = svs.components.dialog;
const {
  templates
} = svs.components.customer_login;
const getActionAndIconForError = clientCode => {
  switch (clientCode) {
    case ClientErrorCodes.GENERIC:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.GENERIC]
      };
    case ClientErrorCodes.NOT_ENOUGH_DATA:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.NOT_ENOUGH_DATA]
      };
    case ClientErrorCodes.NOT_WAGER:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.NOT_WAGER]
      };
    case ClientErrorCodes.NOT_ENOUGH_MONEY:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.NOT_ENOUGH_MONEY]
      };
    case ClientErrorCodes.NOT_AUTHENTICATED:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.NOT_AUTHENTICATED]
      };
    case ClientErrorCodes.PAYMENT_REQUEST_FAILURE:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.PAYMENT_REQUEST_FAILURE]
      };
    case ClientErrorCodes.PAYMENT_FETCH_FAILURE:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.PAYMENT_FETCH_FAILURE]
      };
    case ClientErrorCodes.USER_HAS_EXCLUSION:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.USER_HAS_EXCLUSION]
      };
    case ClientErrorCodes.BAD_DRAW:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.BAD_DRAW]
      };
    case ClientErrorCodes.BAD_DRAW_RACING_REG_BET_DISABLED:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.BAD_DRAW_RACING_REG_BET_DISABLED],
        actions: [{
          title: 'Okej',
          callback: () => {
            location.href = svs.core.urlMapping.get('racingArena');
          }
        }]
      };
    case ClientErrorCodes.BACKEND_ERROR:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.BACKEND_ERROR]
      };
    case ClientErrorCodes.BAD_RESULT:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.BAD_RESULT]
      };
    case ClientErrorCodes.CUSTOMER_LIMIT:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.CUSTOMER_LIMIT]
      };
    case ClientErrorCodes.BAD_BET:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.BAD_BET]
      };
    case ClientErrorCodes.TIME_LIMIT:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.TIME_LIMIT],
        actions: [{
          title: 'Stäng',
          type: button.SECONDARY
        }, {
          title: 'Till mina gränser',
          type: button.PRIMARY,
          callback: () => {
            location.href = svs.core.urlMapping.get('myProfileLimits');
          }
        }]
      };
    case ClientErrorCodes.PLAYER_PAUSED:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.PLAYER_PAUSED],
        actions: [{
          title: 'Kundservice',
          type: button.SECONDARY,
          callback: () => {
            location.href = svs.core.urlMapping.get('customerServiceHome');
          }
        }, {
          title: 'Stäng',
          type: button.SECONDARY
        }]
      };
    case ClientErrorCodes.AMOUNT_LIMIT:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.AMOUNT_LIMIT],
        actions: [{
          title: 'Okej'
        }]
      };
    case ClientErrorCodes.DAILY_AMOUNT_LIMIT:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.DAILY_AMOUNT_LIMIT],
        actions: [{
          title: 'Okej'
        }]
      };
    case ClientErrorCodes.ROW_LIMIT:
      return {
        icon: ClientErrorIcon[ClientErrorCodes.ROW_LIMIT],
        actions: [{
          title: 'Okej'
        }]
      };
    default:
      return {
        title: 'Något gick fel',
        errorMessage: 'Något gick fel vid betalningen, försök igen om en liten stund.',
        icon: ClientErrorIcon[ClientErrorCodes.GENERIC]
      };
  }
};

const showSuspensionMessage = extraInfo => {
  const options = {
    method: 'GET',
    path: '/player/1/suspensions'
  };
  const errorFn = () => {
    svs.components.dialog.api.add(new Confirm({
      branding: branding.SPORT,
      icon: 'spelpaus',
      title: 'Du har en aktiv spelpaus',
      message: [{
        type: message.TEXT,
        text: "G\xE5 till Spelpaus f\xF6r att l\xE4sa mer."
      }],
      actions: [{
        title: 'Spelpaus',
        callback: () => {
          location.href = urlMapping.get('gamingResponsibilityPause');
        }
      }, {
        title: 'Okej'
      }]
    }));
  };
  const successFn = response => {
    const activeSuspension = response.suspensions.filter(suspension => suspension.product === 'all' || suspension.product === 'quickGames');
    activeSuspension.forEach(suspension => {
      suspension.duration = dateFns.differenceInMonths(new Date(suspension.validTo || suspension.terminable), new Date(suspension.validFrom));
    });

    svs.components.dialog.api.add(new Confirm({
      branding: branding.SPORT,
      icon: 'spelpaus',
      message: [{
        type: message.TEXT,
        text: templates.suspension_message({
          suspensions: activeSuspension,
          extraInfo: extraInfo ? extraInfo : ''
        })
      }],
      actions: [{
        title: 'O'
      }]
    }));
    (void 0).abortCallback();
  };
  jupiter.call(options, successFn, errorFn);
};
setGlobal('svs.components.wagerPaymentFlow.paymentFailure.utils', {
  getActionAndIconForError,
  showSuspensionMessage
});

 })(window);