(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/wager-payment-flow/payment-failure/assets/javascripts/payment-failure.js') >= 0) return;  svs.modules.push('/components/wager-payment-flow/payment-failure/assets/javascripts/payment-failure.js');
"use strict";


const {
  dialog
} = svs.components;
const {
  Confirm,
  branding,
  type,
  message
} = dialog;
const {
  canPlayOnVertical
} = svs.accountservices.player_exclusions;
const {
  getActionAndIconForError,
  showSuspensionMessage
} = svs.components.wagerPaymentFlow.paymentFailure.utils;
const {
  ClientErrorCodes
} = svs.components.payment.common.error.constants;
const paymentFailureDialog = async function () {
  let {
    title,
    message: errorMessage
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let clientCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ClientErrorCodes.GENERIC;
  if (clientCode === ClientErrorCodes.SUSPENSION) {
    showSuspensionMessage();
  } else if (clientCode === ClientErrorCodes.VERTICAL_EXCLUSION) {
    const canPlay = await canPlayOnVertical('tipsspel');
    canPlay && paymentFailureDialog({
      title: 'Prova igen om en stund',
      message: 'Något gick tyvärr fel'
    }, ClientErrorCodes.GENERIC);
  } else {
    const {
      actions: errorAction,
      icon
    } = getActionAndIconForError(clientCode);
    dialog.api.add(new Confirm({
      branding: branding.SPORT,
      area: dialog.area.POPUP,
      autoClose: 0,
      icon: icon !== null && icon !== void 0 ? icon : 'exclamation-sign',
      type: type.DEFAULT,
      title: title !== null && title !== void 0 ? title : 'Något gick visst fel',
      message: Array.isArray(errorMessage) ? errorMessage.map(text => ({
        type: message.TEXT,
        text,
        align: svs.components.dialog.message.align.CENTER
      })) : [{
        type: message.TEXT,
        text: errorMessage !== null && errorMessage !== void 0 ? errorMessage : 'Försök igen lite senare'
      }],
      actions: errorAction ? errorAction : [{
        title: 'Okej',
        showLoader: true,
        callback() {
          dialog.api.close();
        }
      }]
    }));
  }
};
setGlobal('svs.components.wagerPaymentFlow.paymentFailure.paymentFailureDialog', paymentFailureDialog);

 })(window);